.authBox {
  position: relative;
  padding: 30px 10px;
  padding-top: 50px;
  width: 290px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px, rgba(0, 0, 0, 0.3) 0px 2px 4px -1px;
  overflow-y: auto;
}

.selectLanguage {
  position: absolute;
  top: 10px;
  right: 10px;
}
