@import "styles/_colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.title {
  color: white;
  font-weight: 700;
  font-size: 70px;
  font-size: clamp(30px, 4vw, 70px);
}

.question {
  color: black;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}

.hero {
  background: url("bg_tneu.png") no-repeat center center/cover;
  height: 50vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.heroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  max-width: 80%;
  word-break: break-word;
  z-index: 2;
  font-size: clamp(14px, 2.5vw, 28px);
}

.heroContentMobile {
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: calc(50vh - 80px);
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 40px;
  z-index: 2;
}

.lndDiscription {
  margin-bottom: 14px;
}

.logoText {
  margin-left: 20px;
}

.featuresSection {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.featureItem {
  flex: 1 1 250px;
  max-width: 300px;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.icon {
  background-color: var(--orange-secondary);
  color: white;
  font-size: 40px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.featureTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.featureText {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

/////////////////////

.mailWrap {
  background: url("map-image.png") no-repeat center center/cover;
  width: 100%;
  padding: 0 24px 30px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  flex-direction: column;
}

.contact {
  color: white;
  font-weight: 700;
  font-size: 30px;
  margin-top: 20px;
}
