@import "styles/_colors";

.wrap {
  position: relative;
  margin: 0 14px 0 14px;
  border-radius: 10px;
  padding: 14px;
  padding-right: 38px;
  padding-left: 6px;
  margin-bottom: 10px;
  background-color: var(--white);
  border-left: 10px solid transparent;
  -webkit-box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 6px 14px -4px rgba(34, 60, 80, 0.2);

  cursor: pointer;
}

.wrapOpacity {
  background-color: var(--gray-light);
}

.wrapOwner {
  border-left-color: var(--orange-secondary);
}

.info {
  width: 100%;
}

.name {
  color: var(--black);
  font-size: 28px;
}

.message {
  font-size: 16px;
  color: var(--black);
}

.count {
  font-size: 26px;
}

.qr {
  width: 110px;
  height: 110px;
  margin-bottom: 6px;
}

.incorrect {
  color: var(--black);
  background-color: var(--error);
  border-radius: 4px;
  display: inline-block;
  padding: 0 4px;
}
