@import "styles/_colors";

.row {
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-gray2);
  border-left: 10px solid transparent;
  transition: background-color 0.3s;
}

.rowOwner {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left-color: var(--orange-secondary);
}

.row:nth-child(2) {
  border-top-left-radius: 0px;
}

.rowToModal {
  border-bottom: none;
}

.cell {
  padding: 10px;
  width: calc(100% / 3);
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}

.expandedRow {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.timeCell {
  margin-top: 2px;
  margin-left: 20px;
}

.messageCell {
  margin-bottom: 10px;
}

.expanded {
  background-color: var(--gray-light);
}

.statusCell {
  // min-width: 116px;
  font-weight: bold;
}

.status-2 {
  color: var(--status2);
}

.status-1 {
  color: var(--status1);
}

.status-4 {
  color: var(--status4);
}

.status-3 {
  color: var(--status3);
}

.buttons {
  display: flex;
  gap: 4px;
  justify-content: space-between;
}

.createdDateCell {
  margin-top: 10px;
  text-align: right;
}

.secondaryTextCell {
  opacity: 60%;
  font-size: 14px;
}

.addressCell {
  margin-bottom: 16px;
}

.address {
  margin-bottom: 10px;
}
