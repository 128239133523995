.wrapPage {
  // height: calc(var(--vh, 1vh) * 100 - 112px);   //nobeta-version
  height: calc(var(--vh, 1vh) * 100 - 158px);
  overflow-y: auto;
  padding: 10px 12px 12px 12px;
  position: fixed;
  // top: 56px;   //nobeta-version
  top: 102px;
  left: 0;
  right: 0;
  bottom: 56px;
}

.fullScreenWrap {
  top: 0;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0;
}

.withoutMenuWrap {
  top: 0;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
}
